import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class OCRecepcionService {
  async registrarReciboOc(datos) {
    return await fetchWrapper.post(`${ruta}/recibo_oc`, datos);
  }
  async obtenerRecibo(id) {
    return await fetchWrapper.get(`${ruta}/recibo_oc/detalle_recibo/${id}`);
  }

  async obtenerTipoPagos() {
    return await fetchWrapper.get(`${ruta}/tipo_pagos`);
  }
  
  async getprecio_venta_producto(datos) {
		return await fetchWrapper.post(`${ruta}/precio_venta_producto`, datos);
	}

  async actualizarFechaVence(datos) {
    return await fetchWrapper.post(`${ruta}/actualizar_fecha_vence/${datos}`, datos);
  }
  async getDatosNecesariosOC() {
		return await fetchWrapper.post(`${ruta}/datos-necesarios-oc`);
	}
}
